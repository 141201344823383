import Vue from 'vue';
import VueRouter from 'vue-router';
import { auth } from '@/firebase';
import firebase from 'firebase/app';

Vue.use(VueRouter);
var userClaims = {};

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: () => {
      return { path: '/admin/dashboard' };
    },
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/Dashboard.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/access-denied');
      }
    },
  },
  {
    path: '/admin/blog',
    name: 'PostsAdminView',
    component: () => import('@/views/PostsView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/admin/blog/edit/:postLink',
    name: 'EditPostAdminView',
    component: () => import('@/views/EditPostView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/admin/pages/edit/:pageId',
    name: 'EditPageView',
    component: () => import('@/views/EditPageView.vue'),
    beforeEnter: async (to, from, next) => {
      await firebase.getCurrentUser().then((user) => {
        if (user) {
          user.getIdTokenResult().then((claims) => {
            userClaims = claims.claims;
          });
        }
      });

      if (!auth.currentUser) {
        next('/login');
      } else if (userClaims.admin) {
        next();
      } else {
        next('/');
      }
    },
    meta: {
      protected: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginView.vue'),
  },
  {
    path: '/access-denied',
    name: 'accessDenied',
    component: () => import('@/views/AccessDenied.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

firebase.getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async (user) => {
      unsubscribe();
      resolve(user);
    }, reject);
  });
};

export default router;
