<template>
  <v-app>
    <component :user="user" :is="layout">
      <router-view :user="user" :layout.sync="layout" />
    </component>
  </v-app>
</template>

<script>
import * as fb from '@/firebase';
import '@/app.css';
export default {
  name: 'App',

  data: () => ({
    layout: `div`,
    user: null,
    authUser: null,
  }),
  watch: {
    authUser: {
      immediate: true,
      async handler() {
        if (this.authUser) {
          this.user = this.authUser;
        }
      },
    },
  },
  async created() {
    if (fb.auth.currentUser) {
      await this.$bind(
        'authUser',
        fb.db.collection('users').doc(fb.auth.currentUser.uid)
      );
    }
  },
};
</script>
