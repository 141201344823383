import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/functions';
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCJbX0ZKqhu1EtPkHJsPdgm4N_QIriLrrI',
  authDomain: 'contractscope.firebaseapp.com',
  projectId: 'contractscope',
  storageBucket: 'contractscope.appspot.com',
  messagingSenderId: '886365428558',
  appId: '1:886365428558:web:234e406a5015bb81e31c1f',
  measurementId: 'G-C8YX40D4S2',
};

const dev = false;
const devData = false;
const devFunctionsOnly = false;

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore();
const auth = firebase.auth();
const storage = firebase.storage();
const analytics = firebase.analytics();
const functions = firebase.functions();
if (devFunctionsOnly) {
  functions.useEmulator('localhost', 5001);
}
if (dev) {
  db.useEmulator('localhost', 8082);
  auth.useEmulator('http://localhost:9099');
  storage.useEmulator('localhost', 9199);
  functions.useEmulator('localhost', 5001);
}

export { db, auth, storage, analytics, functions, devData };
